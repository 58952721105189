import cx from 'classnames';
import React from 'react';

import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel';
import { Hero } from '../../components/blog/hero';
import { PostSidebar } from '../../components/blog/post-sidebar';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs';
import { Container } from '../../components/container';
import SEO from '../../components/seo';
import img1 from './post-5.jpg';
import styles from './post-grid.module.css';

const Post5 = () => {
    return (
      <div className={styles.wrapper}>
        <SEO
          title={"Jak na zdravé a udržitelné hubnutí | NutritionPro"}
          description={"Věděli jste, jaké je správné rozložení živin při hubnutí? Podívejte se, jak dosáhnout zdravého, a hlavně udržitelného hubnutí."}
        />
        <Container>
          <Breadcrumbs style={{ margin: "24px 0" }}>
            <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
            <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Jak na zdravé a udržitelné hubnutí?
            </BreadcrumbsItem>
          </Breadcrumbs>
        </Container>
        <Container>
          <div className={styles.postGrid}>
            <div>
              <Hero
                title="Jak na zdravé a udržitelné hubnutí?"
                date="26.03.22"
              />
              <div>
                <p className={styles.postText}>
                Hubnutí není jednoduché, ale pokud víte, jak na to, máte polovinu úspěchu za sebou. Jenže nejde jen o to zhubnout, ale měli byste se snažit <b>zhubnout trvale, zdravě</b> a vyhnout se takzvanému jojo efektu, který je často problémem rychlého hubnutí. Abyste mohli zdravě a trvale zhubnout, je důležité znát <b>ideální rozložení živin v jídelníčku</b>. Ukážeme vám, jaký by měl být ideální poměr bílkovin, sacharidů a tuků.
                </p>
                <img
                  src={img1}
                  className={styles.postImg}
                  style={{ marginBottom: "40px" }}
                  alt="img1"
                />                
                <h5 className={styles.postTitle}>
                Zaměřte se při hubnutí na ideální poměr živin
                </h5>
                <p className={styles.postText}>
                Zapomeňte na různé drastické diety, protože nevedou k ničemu dobrému, už vůbec ne k udržitelnému výsledku. Abyste nastartovali zdravé hubnutí, měly by váš jídelníček tvořit <b>kvalitní bílkoviny, komplexní sacharidy</b> a <b>tuky</b>. Nezapomeňte do svého jídelníčku zařadit více zeleniny – ideálně ke každému jídlu. Ovoce si naopak dopřejte na snídani.
                </p>
                <ul className={styles.postList}>
                  <li>Podíl <strong>bílkovin</strong> by měl být při redukci hmotnosti okolo <strong>40 %.</strong></li>
                  <li>Podíl <strong>tuků</strong> je při redukci hmotnosti ideální okolo <strong>30 %.</strong></li>
                  <li>Podíl <strong>sacharidů</strong> při redukci hmotnosti držte také okolo <strong>30 %.</strong></li>
                </ul>
                <p className={styles.postText}>
                <em><b>TIP</b>: Věděli jste, že správný pitný režim vám může pomoct zhubnout? Podívejte se, <a href="https://nutritionpro.cz/blog/post-3/">proč je důležitý dostatečný příjem tekutin</a>.</em>
                </p>
                <h5 className={styles.postTitle}>Kvalitní bílkoviny</h5>
                <p className={styles.postText}>
                Bez kvalitních bílkovin se při zdravém hubnutí neobejdete. Mají hned několik výhod. Především vás <b>zasytí na dlouhou dobu</b> a nebudete tak během hubnutí tolik pociťovat hlad. Kromě toho jsou <b>základem svalové hmoty</b>. Stejně tak tvoří protilátky a krevní elementy. Jednoduše řečeno, bílkoviny jsou <b>základním stavebním kamenem lidského těla</b> a zároveň nepostradatelnou živinou.
                </p>
                <h5 className={styles.postTitle}>Komplexní sacharidy</h5>
                <p className={styles.postText}>
                Naše tělo ze sacharidů běžně <b>získává energii</b>. Potřebujete je pro zabezpečení mnoha procesů v těle, stejně jako pro zdravé a udržitelné hubnutí. Proto například nízkosacharidové diety nejsou ideální cestou, jak zhubnout. Zaměřte se na komplexní sacharidy.
                </p>
                <h5 className={styles.postTitle}>Vhodné tuky</h5>
                <p className={styles.postText}>
                Často se stává, že lidé považují tuky za špatné, jenže problematika tuků je daleko složitější. Nesnažte se je při dietě vyřadit. Opět se zaměřte na ty správné tuky – <b>ideálně z ryb, oříšků</b> nebo různých <b>semínek</b>. 
                </p>
                <h5 className={styles.postTitle}>Krabičky jsou daleko jednodušším řešením</h5>
                <p className={styles.postText}>
                Počítat neustále kalorie, sestavovat si jídelníček s ideálním poměrem živin, a to vše dlouhodobě udržet, není snadné. Mnoho lidí vzdá dietu právě kvůli tomu, že je 
                rychle přestane bavit všechno tohle řešit. <b>Proto jsou <a href="https://nutritionpro.cz/blog/post-4/">skvělou volbou krabičky</a>, které vám dovezeme – jednoduše je otevřete a sníte</b>. Nemusíte vůbec nic řešit. Vybrat si můžete hned z několika variant krabičkové diety. 
                </p>
              </div>
            </div>
            <PostSidebar />
          </div>
        </Container>
      </div>
    )
  }
  
  export default Post5